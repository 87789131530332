
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AnimatePresence, motion } from 'framer-motion';
import { DefaultSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Analytics } from '@vercel/analytics/react';
import Layout from '../layout/Layout';
import './styles.scss';
function CustomApp({ Component, pageProps, router }: AppProps) {
    const { t, lang } = useTranslation('common');
    const easing = [0.175, 0.85, 0.42, 0.96];
    const textVariants = {
        exit: { y: 100, opacity: 0, transition: { duration: 0.25, ease: easing } },
        enter: {
            y: 0,
            opacity: 1,
            transition: { delay: 0.1, duration: 0.25, ease: easing },
        },
    };
    const languages = [
        { name: 'English', locale: 'en', href: 'https://knswindows.com/' },
        { name: 'Polski', locale: 'pl', href: 'https://knsokna.pl/' },
        { name: 'Deutsch', locale: 'de', href: 'https://knsfenster.de/' },
        { name: 'Čeština', locale: 'cs', href: 'https://kns-okna.eu/' },
        { name: 'Italiano', locale: 'it', href: 'https://knsfinestre.it/' },
        { name: 'Français', locale: 'fr', href: 'https://knsfenetres.fr/' },
        { name: 'Magyar', locale: 'hu', href: 'https://knsokna.eu/' },
    ];
    return (<>
      <DefaultSeo title={t('title')} openGraph={{
            type: 'website',
            locale: lang,
            // @ts-ignore
            url: languages.flatMap((item) => item.locale === lang && item.href),
            site_name: 'SiteName',
            images: [
                {
                    url: '/ogImage.jpg',
                    width: 1200,
                    height: 630,
                    alt: 'Śląska Fabryka Okien KNS. Produkujemy okna przy użyciu najnowszych technologii',
                },
            ],
        }}/>
      <Head>{/* <title>KNS okna - Śląska Fabryka Okien "KNS"</title> */}</Head>
      <Layout>
        <AnimatePresence mode="wait" initial={false}>
          <motion.main key={router.route} initial="exit" animate="enter" exit="exit" variants={textVariants}>
            <Component {...pageProps}/>
            <Analytics />
          </motion.main>
        </AnimatePresence>
      </Layout>
    </>);
}
const __Next_Translate__Page__191b6c1a042__ = CustomApp;

    export default __appWithI18n(__Next_Translate__Page__191b6c1a042__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  